export const AVAILABLE_LANGUAGES = {
  en: {
    name: "English",
    code: "en"
  },
  uk: {
    name: "Українська",
    code: "uk"
  }
}

export const LANGUAGES_LIST = [
  {
    name: "English",
    code: "en"
  },
  {
    name: "Українська",
    code: "uk"
  }
]


export const RF = "React Flow";
export const SEL = "Selectors";
export const RC = "ReSell";

export const RF_SITE_LINK = "react-flow";
export const SEL_SITE_LINK = "selectors";
export const RC_SITE_LINK = "resell";

export const APP_STORE_LINK = `https://apps.shopify.com/partners/devit-software`;
export const THEME_STORE_LINK = "https://themes.shopify.com/";

export const DEVIT_HELP_CENTER = "https://help.devit.software/en/";

export const ACADEMY_LINK = "https://devit.academy/";
export const JOBS_LINK = "https://jobs.devit.group/";

export const RF_TESTIMONIALS_LINK = "https://apps.shopify.com/react-flow/reviews?ratings%5B%5D=5&sort_by=newest";
export const RF_TESTIMONIALS_CACHE_KEY = "react-flow-testimonials";

export const SEL_TESTIMONIALS_LINK = "https://apps.shopify.com/selectors/reviews?ratings%5B%5D=5&sort_by=newest";
export const SEL_TESTIMONIALS_CACHE_KEY = "selectors-testimonials";

export const RC_TESTIMONIALS_LINK = "https://apps.shopify.com/resell/reviews?ratings%5B%5D=5&sort_by=newest";
export const RC_TESTIMONIALS_CACHE_KEY = "resell-testimonials";

export const REACT_FLOW_LINK = "https://apps.shopify.com/react-flow";
export const SELECTORS_LINK = "https://apps.shopify.com/selectors";
export const RESELL_LINK = "https://apps.shopify.com/resell";

export const SEL_APP_LINK = "https://selectors.devit.software/";
export const RF_APP_LINK = "https://reactflow.devit.software";
export const RC_APP_LINK = "https://resell.devit.software/";

export const TESTIMONIAL_CACHE_TIME = 24 * 1000 * 60 * 60;
export const VIDEO_HASH_TAG = "video";

export const IDENTITY_HEADER = "email-from-identity-header"

export const TRAINING_IDENTITY = "training";
export const CONTACT_US_IDENTITY = "training";
export const HIRE_IDENTITY = "hire";

export const SHOULD_COOKIE_BANNER = "should_cookie_banner";
export const COOKIE_STORAGE_NAME = "usable_cookies";
export const COOKIE_FUNCTIONALITY = "cookie_functionality";
export const COOKIE_TARGETING = "cookie_targeting";
export const COOKIE_ANALYTICS = "cookie_analytics";
export const COOKIE_STRICTLY = "cookie_strictly";

export const SHOULD_SUGGESTION = "suggestion";

export const SITE_URL = "https://devit.software"

export const HR_SOFTWARE = "hr@devit.software";
export const HR_GROUP = "hr@devit.group";
export const SUPPORT_GROUP = "support@devit.software"

export const SOFTWARE_PHONE = {
  pretty: "+38 (091) 481-02-16",
  code: "+380914810216"
}
export const GROUP_PHONE = {
  pretty: "+1 (929) 237-1255",
  code: "+19292371255"
}



