const RF_NAMESPACE = "reactFlow";
const SEL_NAMESPACE = "selectors";
const HOME_NAMESPACE = "home";
const RC_NAMESPACE = "recharge";
const COMMON_NAMESPACE = "common";
const TRAINING_NAMESPACE = "training";
const ABOUT_NAMESPACE = "about";
const HIRE_NAMESPACE = "hire";
const PRIVACY_NAMESPACE = "privacy";
const TERM_OF_USE_NAMESPACE = "term-of-use";
const ACCEPT_COOKIES_NAMESPACE = "accept-cookie";
const COOKIE_POLICY_NAMESPACE = "cookie-policy";
const CONTACT_US_NAMESPACE = "contact-us";
const CUSTOMER_SUPPORT_NAMESPACE = "customer-support";
const APPS_NAMESPACE = "apps";
const SUGGESTION_NAMESPACE = "suggestion";
const DISCORD_TELEGRAM_PRIVACY_NAMESPACE = "discord-telegram-privacy";

module.exports = {
  RF_NAMESPACE,
  SEL_NAMESPACE,
  HOME_NAMESPACE,
  RC_NAMESPACE,
  COMMON_NAMESPACE,
  TRAINING_NAMESPACE,
  ABOUT_NAMESPACE,
  HIRE_NAMESPACE,
  PRIVACY_NAMESPACE,
  TERM_OF_USE_NAMESPACE,
  ACCEPT_COOKIES_NAMESPACE,
  COOKIE_POLICY_NAMESPACE,
  APPS_NAMESPACE,
  SUGGESTION_NAMESPACE,
  CUSTOMER_SUPPORT_NAMESPACE,
  CONTACT_US_NAMESPACE,
  DISCORD_TELEGRAM_PRIVACY_NAMESPACE
}