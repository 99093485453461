const {COMMON_NAMESPACE, ACCEPT_COOKIES_NAMESPACE, HOME_NAMESPACE, RF_NAMESPACE, SEL_NAMESPACE, RC_NAMESPACE,
  TRAINING_NAMESPACE, ABOUT_NAMESPACE, HIRE_NAMESPACE, PRIVACY_NAMESPACE, TERM_OF_USE_NAMESPACE, COOKIE_POLICY_NAMESPACE,
   APPS_NAMESPACE, SUGGESTION_NAMESPACE, CUSTOMER_SUPPORT_NAMESPACE, CONTACT_US_NAMESPACE,
  DISCORD_TELEGRAM_PRIVACY_NAMESPACE
} = require("./utils/i18nNameSpaces");

module.exports = {
  "locales": ["en", "uk"],
  "defaultLocale": "en",
  localeDetection: false,
  "pages": {
    "*": ["footer", "header", COMMON_NAMESPACE, ACCEPT_COOKIES_NAMESPACE, SUGGESTION_NAMESPACE],
    "/": [HOME_NAMESPACE],
    "/react-flow": [RF_NAMESPACE],
    "/selectors": [SEL_NAMESPACE],
    "/resell": [RC_NAMESPACE],
    "/training-center": [TRAINING_NAMESPACE],
    "/about-us": [ABOUT_NAMESPACE],
    "/hire": [HIRE_NAMESPACE],
    "/privacy": [PRIVACY_NAMESPACE],
    "/term-of-use": [TERM_OF_USE_NAMESPACE],
    "/cookie-policy": [COOKIE_POLICY_NAMESPACE],
    "/contact-us": [CONTACT_US_NAMESPACE, TRAINING_NAMESPACE],
    "/apps": [APPS_NAMESPACE],
    "/customer-support": [CUSTOMER_SUPPORT_NAMESPACE, TRAINING_NAMESPACE],
    "/discord/telegram/privacy": [DISCORD_TELEGRAM_PRIVACY_NAMESPACE]
  },
}